<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="55%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- periode -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Periode"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="periode"
                        has-feedback>
                        <AMonthPicker
                            v-model:value="form.periode"
                            :allow-clear="false"
                            placeholder="Pilih Periode"
                            :disabled-date="disabledDate"
                            format="MMMM YYYY"
                            :disabled="form.readOnly || (onlyDisable && form.id)"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih vendor -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Distributor"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="vendor_id"
                        has-feedback>
                        <FilterDistributor
                            v-model:value="form.vendor_id"
                            :disabled="form.readOnly || (onlyDisable && form.id)"
                            style="width:100%;"
                            :mode="null"
                            :join-options="
                                form.vendor_id
                                    ? [{ id: form.vendor_id, name: `${item.kode_distributor} - ${item.nama_distributor}` }]
                                    : []"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih kabupaten -->
            <ARow
                v-if="validateRoles([ROLE_ADMIN_BK, ROLE_SPC])"
                class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Kabupaten/Kota"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="distrik_id"
                        has-feedback>
                        <FilterWilayahDistributor
                            v-model:value="form.distrik_id"
                            v-model:distributor="form.vendor_id"
                            :disabled="form.readOnly || (onlyDisable && form.id)"
                            style="width:100%;"
                            :mode="null"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih shipto -->
            <ARow
                v-if="validateRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR])"
                class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Shipto"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="shipto_id"
                        has-feedback>
                        <FilterGudang
                            v-model:value="form.shipto_id"
                            v-model:distributor="form.vendor_id"
                            :disabled="form.readOnly || (onlyDisable && form.id)"
                            style="width:100%;"
                            placeholder="Pilih Shipto"
                            :mode="null"
                            :join-options="
                                form.shipto_id
                                    ? [{ id: form.shipto_id, code: item.shipto_code, name: item.shipto_name }]
                                    : []"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih customer -->
            <ARow
                v-if="validateRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR])"
                class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Customer"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="customer_id"
                        has-feedback>
                        <FilterCustomerMappingVendor
                            v-model:value="form.customer_id"
                            v-model:distributor="form.vendor_id"
                            :disabled="form.readOnly || (onlyDisable && form.id)"
                            style="width:100%;"
                            :mode="null"
                            :join-options="
                                form.customer_id && item.id
                                    ? [{ id: form.customer_id,  code: item.kode_customer, name: item.nama_customer }]
                                    : []"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih user target -->
            <ARow
                v-if="hasRoles([ROLE_ADMIN_BK])"
                class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="User Target"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="user_target_id"
                        has-feedback>
                        <FilterUsers
                            v-model:value="form.user_target_id"
                            :disabled="form.readOnly"
                            :role_id="state.role_id"
                            :nopeg_status="0"
                            style="width:100%;"
                            placeholder="Pilih User Target"
                            :mode="null"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- market sale -->
            <ARow
                v-if="validateRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR])"
                class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Market Sale"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="market_sale_target"
                        has-feedback>
                        <AInput
                            v-model:value="form.market_sale_target"
                            placeholder="market sale"
                            :disabled="form.readOnly"
                            type="number"
                            :min="0"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- demand total -->
            <ARow
                v-if="validateRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR])"
                class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Demand Total"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="demand_all_brand"
                        has-feedback>
                        <AInput
                            v-model:value="form.demand_all_brand"
                            placeholder="semua total demand"
                            :disabled="form.readOnly"
                            type="number"
                            :min="0"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <ASkeleton
                active
                :paragraph="{ rows: 3 }"
                :loading="state.loadingBrand">
                <!-- brands -->
                <ARow class="form-row">
                    <ACol :sm="24">
                        <ACard size="small">
                            <strong class="my-4 h5">Target Per Brand</strong>
    
                            <ASpace
                                direction="vertical"
                                style="width:100%">
                                <ARow
                                    v-for="(item, index) in form.brands"
                                    :key="index"
                                    class="form-row">
                                    <ACol :sm="24">
                                        <AFormItem
                                            :label="item.brand_name"
                                            label-align="left"
                                            :label-col="{ sm: { span: 4 } }"
                                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                                            :name="['brands', index, 'demand_value']"
                                            has-feedback>
                                            <AInput
                                                placeholder="demand"
                                                type="number"
                                                :min="0"
                                                :disabled="form.readOnly"
                                                v-model:value="item.demand_value"
                                                style="width:100%;"/>
                                        </AFormItem>
                                    </ACol>
                                </ARow>
                            </ASpace>
                        </ACard>
                    </ACol>
                </ARow>
            </ASkeleton>

            <ARow
                v-if="!form.readOnly"
                class="form-row mt-2"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import apiClient from '@/services/axios'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterCustomerMappingVendor from '@/components/filter/FilterCustomerMappingVendor'
import FilterUsers from '@/components/filter/FilterUsers'
import FilterWilayahDistributor from '@/components/filter/FilterWilayahDistributor'
import Form from 'vform'
import { message } from 'ant-design-vue'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_SPC,
    ROLE_DISTRIBUTOR,
    ROLE_ASM,
    ROLE_SSM,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterGudang,
        FilterDistributor,
        FilterCustomerMappingVendor,
        FilterUsers,
        FilterWilayahDistributor,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
        roles: {
            type: Array,
            default: () => [],
        },
        // untuk membuat input disable secara khusus
        onlyDisable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            periode: null,
            shipto_id: [], // gudang
            distrik_id: [], // kabupaten/kota
            vendor_id: [], // distributir
            customer_id: [], // customer
            user_target_id: [],
            market_sale_target: null,
            demand_all_brand: null,
            brands: [],
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Target Distributor',
            endpoint: {
                current: '/api/snop/target-penjualan-distributor',
                brand: '/api/snop/target-penjualan-distributor/brands',
                target_spc: `/api/snop/target-penjualan-distributor/${props.item.id}/target-spc`,
                target_distributor: `/api/snop/target-penjualan-distributor/${props.item.id}/target-distributor`,
            },
            rules: {
                periode: [
                    {
                        required: true,
                        message: 'Periode tidak boleh kosong!',
                    },
                ],
                customer_id: [
                    {
                        required: true,
                        message: 'Customer tidak boleh kosong!',
                    },
                ],
                vendor_id: [
                    {
                        required: true,
                        message: 'Distributor tidak boleh kosong!',
                    },
                ],
                distrik_id: [
                    {
                        required: true,
                        message: 'Kabupaten/kota tidak boleh kosong!',
                    },
                ],
                shipto_id: [
                    {
                        required: true,
                        message: 'Shipto tidak boleh kosong!',
                    },
                ],
                user_target_id: [
                    {
                        required: true,
                        message: 'User target tidak boleh kosong!',
                    },
                ],
                market_sale_target: [
                    {
                        required: true,
                        message: 'Market sale tidak boleh kosong!',
                    },
                ],
                demand_all_brand: [
                    {
                        required: true,
                        message: 'Demand total tidak boleh kosong!',
                    },
                ],
            },
            loadingBrand: false,
            role_id: null,
            brands: [],
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledDate = current => {
            if (current.isSame(new Date(), 'month')) {
                return moment().startOf('month').add(10, 'days').isBefore(current)
            }

            return current && current < moment().startOf('month')
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            form.periode = moment(form.periode).format('YYYY-MM')

            if (!form.id) {
                form.post(state.endpoint.current)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data) {
                            state.errors = data.errors
                        }
                    })
            } else {
                form.put(validateRoles([ROLE_DISTRIBUTOR]) ? state.endpoint.target_distributor : state.endpoint.target_spc)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data) {
                            state.errors = data.errors
                        }
                    })
            }

        }

        const fetchBrand = () => {
            state.loadingBrand = true

            apiClient.get(state.endpoint.brand)
                .then(({ data }) => {
                    state.brand = data

                    data.forEach(item => {
                        form.brands.push({
                            brand_id: item.id,
                            brand_name: item.name,
                            demand_value: null,
                        })
                    })
                })
                .finally(() => {
                    state.loadingBrand = false
                })
        }

        const validateRoles = (roles = []) => {
            return props.roles.every(role => roles.includes(role));
        }

        /**
         * handle func vue
         * 
         * 1: distributor, 747552: spc
         **/
        onMounted(() => {
            // reset form
            form.reset()
            form.readOnly = props.item.readOnly

            if (!form.id) {
                // fetch data brands
                fetchBrand()
            } else {
                form.periode = props.item.periode
                form.customer_id = props.item.customer_id
                form.market_sale_target = props.item.market_sale_target
                form.demand_all_brand = props.item.demand_all_brand
                form.brands = props.item.brands
            }
            
            // change title modal
            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Target `

                if (validateRoles([ROLE_SPC])) {
                    state.title += 'By SPC'

                    state.role_id = 747552
                }
            }

            if (validateRoles([ROLE_DISTRIBUTOR])) {
                state.title += 'By Distributor'
                state.role_id = 1
            }


            form.shipto_id = props.item.shipto_id ? props.item.shipto_id : []
            form.vendor_id = props.item.vendor_id ? props.item.vendor_id : []
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledDate,
            validateRoles,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_SPC,
            ROLE_DISTRIBUTOR,
            ROLE_ASM,
            ROLE_SSM,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
